<template>
    <div class="app-container">
         <div class="title">
            <div class="name">救援工单</div>
            <div class="search">
                <Select v-model="queryParams.orderStatus" placeholder="状态" clearable style="width:120px">
                    <Option v-for="(item, index) in orderStatusOptions" :key="index" :value="item.value">
                        {{ item.label }}</Option>
                </Select>
                <DatePicker @on-change="queryParams.startTime = $event" :value="queryParams.startTime" format="yyyy-MM-dd" type="date" placeholder="开始时间" style="width: 180px">
                </DatePicker>
                <DatePicker @on-change="queryParams.endTime = $event" :value="queryParams.endTime" format="yyyy-MM-dd" type="date" placeholder="结束时间" style="width: 180px">
                </DatePicker>
				<Button type="primary" @click="getList()">查询</Button>
            </div>
        </div>
        <MXTable :loading='loading' :list="list" :tableColumns="tableColumns" :total="total" :formItem="queryParams" @search="getList" @onTableRowClick="onTableRowClick"></MXTable>
        <el-dialog top="8vh" custom-class="custom-el-dialog" :title="title" :visible.sync="open" width="50%" append-to-body>
            <Detail ref="detail" v-if="interactType == 1" @edit="onEdit" @afterDel="afterDel" />
            <Save ref="save" v-if="interactType == 2 || interactType == 3" @cancel="onEditCancel" @afterSubmit="afterEditSubmit" />
        </el-dialog>
    </div>
</template>
<script>
import { datasInstance } from '@/pages/maintenance/rescue/datas'
import { InteractType } from "@/utils/constants"
import { getEmergencyRescuePage } from "@/api/maintenance/rescue";
import searchbar from '@/components/SearchBar';
import MXTable from '@/pages/components/mx-ui-components/mx-table'
import Detail from '@/pages/maintenance/rescue/detail'
import Save from '@/pages/maintenance/rescue/save'
export default {
    name: "Rescue",
    components: {
        searchbar,
        MXTable,
        Detail,
        Save
    },
    data() {
        return {
            // 遮罩层
            loading: true,
            // 导出遮罩层
            exportLoading: false,
            // 显示搜索条件
            showSearch: true,
            // 表配置
            tableColumns: datasInstance().getTableColumns(),
            // 总条数
            total: 0,
            // 电梯档案列表
            list: [],
            // 弹出层标题
            title: "",
            // 是否显示弹出层
            open: false,
            // 弹层交互内容 1 详情 2 编辑 3 新增
            interactType: 0,
            // 查询参数
            queryParams: {
                customerName: null,
                contactPerson: null,
                contactPersonPhone: null,
                address: null,
                customerType: null,
                remarks: null,
                createTime: [],
                startTime: this.formatDate(-30),
                endTime: this.formatDate(0),
                pageNo: 1,
                pageSize: 10,
            },
            orderStatusOptions: this.defaultOrderStatusOptions(),
            activeTab: 'info'
        };
    },
    created() {
        this.getList();
    },
    methods: {
        /** 查询列表 */
        getList() {
            this.loading = true;
            // 执行查询
			let params = this.formatTimeParams(
			  'startTime',
			  'endTime',
			  this.queryParams
			)
            getEmergencyRescuePage(params).then(response => {
                this.list = response.info.list;
                this.total = response.info.total;
                this.loading = false;
            }).catch((err) => {
                this.loading = false;
            });
        },
        /** 取消按钮 */
        cancel() {
            this.open = false;
        },
        /** 重置按钮操作 */
        resetQuery() {
            this.resetForm("queryForm");
            this.handleQuery();
        },
        /** 搜索按钮操作 */
        onQuery() {
            this.getList();
        },
        /** 修改按钮操作 */
        onUpdate(row) {},
        /** 删除按钮操作 */
        onDelete(row) {},
        /** 导出按钮操作 */
        onExport() {
            // 处理查询参数
            let params = { ...this.queryParams };
            params.pageNo = undefined;
            params.pageSize = undefined;
            this.$modal.confirm('是否确认导出所有档案数据项?').then(() => {
                this.exportLoading = true;
                return exportEmergencyRescueExcel(params);
            }).then(response => {
                this.$download.excel(response, '档案.xls');
                this.exportLoading = false;
            }).catch(() => {});
        },
        onTableRowClick(row, column, event) {
            this.title = '查看'
            this.open = true
            this.interactType = InteractType.VIEW
            this.$nextTick(() => {
                this.$refs.detail.getBy(row)
            })
        },
        onAdd() {
            this.title = '添加'
            this.open = true
            this.interactType = InteractType.ADD
        },
        onEdit(id) {
            this.title = '编辑'
            this.open = true
            this.interactType = InteractType.EDIT
            this.$nextTick(() => {
                this.$refs.save.getBy({ id })
            })
        },
        onEditCancel(id) {
            this.title = '查看'
            this.interactType = InteractType.VIEW
            this.$nextTick(() => {
                this.$refs.detail.getBy({ id })
            })
        },
        onPageChange(e) {
            this.queryParams.pageNo = e.page
            this.getList()
        },
        afterEditSubmit(id) {
            if (id)
                this.onEditCancel(id)
            else
                this.open = false
            this.onQuery()
        },
        afterDel() {
            this.open = false
            this.onQuery()
        },

        defaultOrderStatusOptions() {
            return [{
                label: '待办',
                value: 0
            }, {
                label: '进行中',
                value: 1
            }, {
                label: '已完成',
                value: 2
            }, ]
        }
    },
    watch: {
        open(val) {
            if (!val)
                this.interactType = 0
        }
    }
};
</script>
<style lang="scss" scoped>
:deep .custom-el-dialog {
    height: 100vh !important;
}

:deep .el-dialog:not(.is-fullscreen) {
    margin-top: 0px !important;
}

:deep .el-dialog {
    max-height: 100%;
}

:deep .el-dialog__body {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    height: calc(100% - 54px) !important;
}

.app-container {
    padding: 0 30px;
}

.app-container {
    padding: 0 30px;
}

.title {
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.name {
    font-size: 18px;
    font-weight: 700;
    color:#fff;
}

.ivu-select,
.ivu-date-picker {
    margin-right: 10px;
}
</style>