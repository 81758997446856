<template>
  <div class="detail-wrapper">
     <el-tabs v-model="activeTab" type="card">
      <el-tab-pane label="救援详情" name="info">
        <Info ref="info" />
      </el-tab-pane>
      <!-- <el-tab-pane label="报修记录" name="elevator-repair">
        <FaultRecord />
      </el-tab-pane>
      <el-tab-pane label="备件记录" name="elevator-exchange">
        <RepairRecord />
      </el-tab-pane> -->
    </el-tabs>
  </div>
</template>
<script>
import { Loading } from 'element-ui';
// import FaultRecord from '@/pages/common/components/faultRecord'
// import RepairRecord from '@/pages/common/components/repairRecord'
import Info from '@/pages/maintenance/rescue/components/info'
export default {
  name: 'RescueDetail',
  components: {
    Info,
    // FaultRecord,
    // RepairRecord
  },
  data() {
    return {
      activeTab: 'info',
      dataId: null,
    }
  },
  methods: {
    /**
     * 根据数据更新
     */
    updateBy(data) {

    },
    /**
     * 根据参数请求获取数据
     */
    getBy(params) {
      this.dataId = params.id
      this.$refs.info.getBy(params)
    },
    onEdit() {
      this.$emit('edit', this.dataId)
    },
    onDel() {
      this.$modal.confirm(`确定要删除编号为【${this.dataId}】的条目吗？`, '提示').then(() => {
        let loadingInstance = Loading.service();
        this.$refs.info.delete(this.dataId, (success) => {
          loadingInstance.close();
          if (success)
            this.$emit('afterDel', this.dataId)
        })
      }).catch(() => {});
    }
  }
}

</script>
<style lang="scss" scoped>
.data-menu-wrapper {
  height: 40px;
  background-color: #fff;
  border-bottom: 1px solid #ebecee;
  margin-bottom: 30px;
}

</style>
