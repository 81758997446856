import http from '@/common/utils/http'

export function getEmergencyRescuePage(data) {
  return http({
    url: `maintenancePlatform/getEmergencyRescueOrderByPhone`,
    method: 'post',
    data
  })
}


// import request from '@/utils/request'

// export function createEmergencyRescue(data) {
//   return request({
//     url: '/shmashine-maintenance/emergency-rescue/create',
//     method: 'post',
//     data: data
//   })
// }

// // 更新急救管理
// export function updateEmergencyRescue(data) {
//   return request({
//     url: '/shmashine-maintenance/emergency-rescue/update',
//     method: 'put',
//     data: data
//   })
// }

// // 删除急救管理
// export function deleteEmergencyRescue(id) {
//   return request({
//     url: '/shmashine-maintenance/emergency-rescue/delete?id=' + id,
//     method: 'delete'
//   })
// }

// 获得急救管理
export function getEmergencyRescue(id) {
  return http({
    url: 'maintenancePlatform/getEmergencyRescueDetailById?id=' + id,
    method: 'post',
    data: {}
  })
}

// // 获得急救管理分页
// export function getEmergencyRescuePage(query) {
//   return request({
//     url: '/shmashine-maintenance/emergency-rescue/page',
//     method: 'get',
//     params: query
//   })
// }

// // 导出急救管理 Excel
// export function exportEmergencyRescueExcel(query) {
//   return request({
//     url: '/shmashine-maintenance/emergency-rescue/export-excel',
//     method: 'get',
//     params: query,
//     responseType: 'blob'
//   })
// }

// //急救流程发起
// export function emergencyRescueOrderBpmInitiated(data) {
//    return request({
//     url: '/shmashine-maintenance/emergency-rescue/emergencyRescueOrderBpmInitiated',
//     method: 'post',
//     data: data
//   })
// }

// // 流程通过接口
// export function emergencyRescueOrderApprove(data) {
//   return request({
//     url: '/shmashine-maintenance/emergency-rescue/emergencyRescueOrderApprove',
//     method: 'post',
//     data: data
//   })
// }